import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createChatroomSelectorById } from '../../../reducers/chatrooms';
import { getChatroom } from '../../../actions/chatrooms';
import { getUrlParams } from '../../../helpers/location';
import Helmet from 'react-helmet';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Table from '../../../components/Table';
import Cell from '../../../components/Table/cell';
import Layout from '../../../components/Layout';
import PrescriptionItem from '../../../components/PrescriptionItem';

export default ({ location }) => {
  const params = getUrlParams(location.search);
  const { id } = params;
  const dispatch = useDispatch();
  const chatroom = useSelector(state => createChatroomSelectorById(state, id));
  const session = useSelector(state => state.session);

  React.useEffect(() => {
    if (id) {
      dispatch(getChatroom(true, id));
    }
  }, [id]);

  if (!chatroom) return false;

  const { staff, customer, is_close, closed } = chatroom;

  if (!is_close) return false;

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>สรุปการพบแพทย์ - MorOnline</title>
      </Helmet>
      <Box p={2}>
        {/* <Box position="absolute" display="flex" flexDirection="column" p={1} alignItems="center" right={0} width={180}>
          <img src="/img/qr.png" height="90"/>
          <small style={{ fontSize: '0.7em' }}>Ref no.<br/>0e7865720bdf4d70abc736aaf5a</small>
        </Box> */}
        <h2>สรุปการพบแพทย์</h2>
        <TableRow>
          <Cell>วันที่</Cell>
          <Cell>{new Date(chatroom.last_updated).toLocaleDateString()}</Cell>
        </TableRow>
        <h3>ผลการตรวจ</h3>
        <Table>
          <TableBody>
            <TableRow>
              <Cell>วันที่พบแพทย์</Cell>
              <Cell>{new Date(chatroom.last_updated).toLocaleDateString()}</Cell>
            </TableRow>
            <TableRow>
              <Cell>ชื่อ</Cell>
              <Cell>{`${customer.first_name} ${customer.last_name}`}</Cell>
            </TableRow>
            <TableRow>
              <Cell>สรุปอาการ</Cell>
              <Cell>{closed.illness_text}</Cell>
            </TableRow>
            <TableRow>
              <Cell>ข้อแนะนำ</Cell>
              <Cell>{closed.suggestion_text}</Cell>
            </TableRow>
          </TableBody>
        </Table>
        {
          !!closed.prescriptions && !!closed.prescriptions.length && (
            <>
              <h3>รายการยา</h3>
              {
                closed.out_of_stock_prescriptions.map((val, index) => {
                  return (
                    <PrescriptionItem {...val} isOutOfStock key={index}/>
                  );
                })
              }
              {
                closed.prescriptions.map((val, index) => {
                  return (
                    <PrescriptionItem {...val} key={index}/>
                  )
                })
              }
            </>
          )
        }

      </Box>
    </Layout>
  );
}
